import * as React from "react";
import type { HeadFC } from "gatsby";

import { Web3Providers } from "../contexts/AppContext";

import { NavBar } from "../components/NavBar/NavBar";

import { PageHeader } from "../components/PageHeader/PageHeader";
import {
  GoHomeButton,
  YellowButton,
} from "../components/Items/Buttons/Buttons";
import { getQueryParams } from "react-use-query-param-string";
import { ethers } from "ethers";
import { EntryVerificationStatus } from "../types";
import { SEO } from "../components/SEO";

const OrdersPage = () => {
  return (
    <Web3Providers>
      <OrdersPageComponent />
    </Web3Providers>
  );
};
const OrdersPageComponent = () => {
  const { address, dropId } = getQueryParams();
  const [approvalCode, setApprovalCode] = React.useState("");
  const [awaitingForEntryConfirmation, setAwaitingForEntryConfirmation] =
    React.useState(false);
  const [isApproved, setIsApproved] = React.useState<EntryVerificationStatus>();
  const [anyErrors, setAnyErrors] = React.useState<string | undefined>(
    undefined
  );

  return (
    <Web3Providers>
      <main className="blog-page">
        <NavBar />

        <div>
          {" "}
          <PageHeader
            title="Tu odbieramy zamówienia :)"
            subtitle={`Bedzie haslo do wpisania dla osoby skanującej`}
          />
          <input
            type="password"
            className="ticket-view__input focus:ring-black-500"
            onChange={e => setApprovalCode(e.target.value)}
          />
          <YellowButton
            className="ticket-view__button btn-clicked theshadow"
            title={"Zatwierdź odbiór"}
            disabled={awaitingForEntryConfirmation}
            action={async () => {
              setIsApproved(undefined);
              setAnyErrors(undefined);

              const encodedPassword = ethers.utils.sha256(
                ethers.utils.toUtf8Bytes(approvalCode)
              );

              setAwaitingForEntryConfirmation(true);
              const response = await fetch(
                `${process.env.API_BASE_URL}/confirm_order`,
                {
                  method: "POST",
                  body: JSON.stringify({
                    address,
                    dropId,
                    approvalCode: encodedPassword,
                  }),
                  headers: {
                    "Content-type": `application/json`,
                  },
                }
              );

              const responseJson = (await response.json()) as {
                status: EntryVerificationStatus;
                reason?: string;
              };

              setIsApproved(responseJson.status);

              if (responseJson.reason) {
                setAnyErrors(responseJson.reason);
              }
              setAwaitingForEntryConfirmation(false);
            }}
          />
          {awaitingForEntryConfirmation && (
            <p className="ticket-view__text-result">
              Polaczek weryfikowany, poczekaj cierpliwie na odpowiedź :)
            </p>
          )}
          {isApproved === EntryVerificationStatus.APPROVED && (
            <p className="ticket-view__text-result">
              Zamówienie zweryfikowane pomyślnie! Wydaj ziomeczkowi item
            </p>
          )}
          {isApproved === EntryVerificationStatus.DENIED && (
            <p className="ticket-view__text-result">
              Nie udało się zweryfikować polaka :(
              <br />
              Dane odczytane z QR:
              <ul>
                <li>Adres: {address}</li>
                <li>Drop Id: {dropId}</li>
              </ul>
              {anyErrors ?? (
                <span>
                  Dodatkowe info o niepomyślnej weryfikacji: {anyErrors}
                </span>
              )}
            </p>
          )}
          <GoHomeButton />
        </div>
      </main>
    </Web3Providers>
  );
};

export default OrdersPage;
export const Head: HeadFC = () => <SEO subtitle="Odbiór dropów" />;
